<template>
  <div class="home-ai-chat" ref="homeAiChat" @scroll="onScroll">
    <!--    权益弹窗-->
    <div class="right-dialog" :class="{hidden: !visibles.rightDialog}">
      <div class="right-dialog-shade" @click="visibles.rightDialog=false"></div>
      <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/ai-empty-dialog-content.png" alt="" class="right-img">
    </div>


    <!--    空列表-->
    <div class="ai-empty" v-if="!chat.id">
      <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/ai-gather-material.png" alt="" class="ai-empty-title">
      <div class="ai-empty-sub-title">自学200万页站内PPT</div>
      <div class="ai-empty-btn" @click="visibles.rightDialog=true">资料均可下载
        <van-icon name="arrow"/>
      </div>
      <div class="ai-empty-folders">
        <div class="ai-empty-folder" v-for="(item,index) in folders" :key="index"
             @click="$router.push(`/ai?id=${item.resultChatId}`)">
          <img :src="`https://cdn.001ppt.cn/h5/ai/folder-icon-${index+1}.png`" alt="" class="folder-icon">
          <div class="folder-info">
            <div class="folder-title">{{ item.title }}</div>
            <div class="folder-sub-title">
              {{ item.subTitle }}
              <img src="https://cdn.001ppt.cn/h5/ai/ai-search-confirm.png" alt="" class="folder-sub-title-icon">
            </div>
          </div>
          <img :src="`https://cdn.001ppt.cn/h5/ai/folder-img-${index+1}.png`" alt="" class="folder-img"
               :style="{height:item.imgHeight+'px',width:item.imgWidth+'px'}">
        </div>
      </div>
    </div>


    <template v-else>
      <!--    结果页-->
      <div class="ai-chat-header">
        <div>AI帮我找: {{ chat.question }}</div>
        <div class="ai-chat-share-btn" @click="share">
          分享
          <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/share.png" alt="">
        </div>
      </div>

      <div class="ai-chat-card">
        <div class="loading" v-if="loadings.loadChat || loadings.loadRefPlans">
          灵感加载中
          <van-loading color="#f15448" size="14px" style="margin-left: 4px"/>
        </div>
        <div class="ai-chat-card-header">
          <div class="ai-chat-card-title">
            <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/search-result.png" alt="">
            资料推荐
          </div>
          <div class="ai-chat-card-btn" @click="resetRefPlans">
            <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/refresh.png" alt="">
            重新推荐
          </div>
        </div>

        <div>
          <div :class="{'show-all':visibles.allRefPlans}" class="ai-chat-ref-plans">
            <template v-for="(item,index) in refPlanPage.records">
              <div class="ref-plan-item" @click="$router.push(`/caseDetail/${item.id}`)">
                <div class="index" :class="`index-${refPlanPage.records.indexOf(item)+1}`">
                  {{ refPlanPage.records.indexOf(item) + 1 }}
                </div>
                <img :src="item.cover" class="cover" alt="">
                <div class="info">
                  <div class="title">{{ item.planTitle }}</div>
                  <div class="tags" style="height: 19px">
                    <template v-for="(tag) in item.planTagName.split(',').filter(t=>t && t.length)">
                      #{{ tag }}
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div class="toggle-all-ref-plans" @click="visibles.allRefPlans = !visibles.allRefPlans">
            <div class="toggle-all-ref-plans-btn" v-if="!visibles.allRefPlans">
              <van-icon name="arrow" style="transform: rotateZ(90deg)"/>
              共{{ refPlanPage.records.length }}篇 · 点击查看更多
              <van-icon name="arrow" style="transform: rotateZ(90deg)"/>
            </div>
            <div class="toggle-all-ref-plans-btn" v-else>
              <van-icon name="arrow" style="transform: rotateZ(-90deg)"/>
              共{{ refPlanPage.records.length }}篇 · 点击收起
              <van-icon name="arrow" style="transform: rotateZ(-90deg)"/>
            </div>
          </div>
        </div>

        <div class="ai-chat-card-header">
          <div class="ai-chat-card-title">
            <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/search-result.png" alt="">
            合集推荐
          </div>
        </div>

        <div class="ref-plan-lists" v-if="refPlanLists && refPlanLists.length">
          <div class="plan-list" v-for="item in refPlanLists" @click="$router.push(`/casePlanDetail/${item.id}`)">
            {{ item.shortTitle || item.title }}({{ item.planNum }}份)
          </div>
        </div>
      </div>


      <div class="ai-chat-card" style="background: #fff6ec">
        <div class="loading" v-if="loadings.loadChat || loadings.loadSummary">
          灵感加载中
          <van-loading color="#f15448" size="14px" style="margin-left: 4px"/>
        </div>
        <div class="ai-chat-card-header">
          <div class="ai-chat-card-title">
            <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/search-result.png" alt="">
            资料总结
          </div>
          <div class="ai-chat-card-btn" @click="reGenSummary">
            <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/cut.png" class="reply-card-btn-icon" alt="">
            换个总结
          </div>
        </div>

        <div class="summary">
          <chat-renderer :content="summary.content" :status="summary.status"/>
        </div>
        <div class="summary-tip">
          <div style="flex-shrink: 0">*灵感AI还在实习，如暂未生成，点右上角「换个总结」</div>
          <div style="display: flex;align-items: center;">
            <img class="summary-handle-icon" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/thumb.svg" alt=""
                 @click="toggleLikeSummary" v-if="!summary.hasLike">
            <img class="summary-handle-icon active" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/thumb-active.svg"
                 alt=""
                 @click="toggleLikeSummary" v-else>
            <img class="summary-handle-icon reverse" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/thumb.svg" alt=""
                 @click="toggleDislikeSummary" v-if="!summary.hasDislike">
            <img class="summary-handle-icon reverse active"
                 src="https://cdn.001ppt.cn/pc2/static/imgs/svg/thumb-active.svg"
                 alt=""
                 @click="toggleDislikeSummary" v-else>
          </div>
        </div>
      </div>

      <div class="ai-chat-card">
        <div class="loading" v-if="loadings.loadChat || loadings.loadRefQuestions">
          灵感加载中
          <van-loading color="#f15448" size="14px" style="margin-left: 4px"/>
        </div>
        <div class="ai-chat-card-header">
          <div class="ai-chat-card-title">
            <img src="https://cdn.001ppt.cn/pc2/ai/knowledge/search-result.png" alt="">
            资料追问
          </div>
        </div>

        <div style="margin-top: 15px">
          <div class="question-item" :class="{last: index === refQuestions.length - 1}"
               v-for="(ques,index) in refQuestions" :key="index" @click="aiAsk(ques)">
            {{ ques }}
          </div>
        </div>
      </div>
    </template>

    <ai-search-input ref="aiSearchInput" :loading="loadings.aiAsk || loadings.loadChat" @ask="aiAsk" style="bottom: 20px" @stop="stopGenSummary"/>
  </div>
</template>

<script>
import {chatApi} from "../../../api/v2/chatV2Api";
import {copyToClipBoard, sleep} from "../../../config/util";
import ChatRenderer from "../../../components/biz/chatRenderer.vue";
import AiSearchInput from "../../../components/biz/aiSearchInput.vue";
import {planListV2Api} from "../../../api/v2/planListV2Api";
import {chatCardV2Api} from "../../../api/v2/chatCardV2Api";

export default {
  components: {ChatRenderer, AiSearchInput},
  props: ['headerHeight', 'currTab'],
  data() {
    return {
      visibles: {
        rightDialog: false,
        allRefPlans: false
      },
      folders: [
        {
          title: '方案找参考',
          subTitle: '美妆小红书种草',
          imgWidth: 99,
          imgHeight: 82,
          resultChatId: '4187198766889263104'
        },
        {
          title: '社媒找洞察',
          subTitle: '2024消费趋势',
          imgWidth: 100,
          imgHeight: 83,
          resultChatId: '4187226959054594048'
        },
        {
          title: '恶补找课件',
          subTitle: '品牌策略模型',
          imgWidth: 100,
          imgHeight: 71,
          resultChatId: '4187227869587660800'
        },
        {
          title: '决策找研报',
          subTitle: '新能源汽车',
          imgWidth: 100,
          imgHeight: 71,
          resultChatId: '4187230291949215744'
        },
      ],
      chat: {
        id: '',
        question: '',
        status: ''
      },
      refPlanPage: {
        records: [],
      },
      refQuestions: [],
      summary: {
        content: '',
        status: '',
        hasLike: false,
        hasDislike: false
      },
      sse: null,
      loadings: {
        loadChat: false,
        loadRefPlans: false,
        loadSummary: false,
        loadRefQuestions: false,
        aiAsk: false
      },
      refPlanLists: []
    }
  },


  mounted() {
    window.clearAiChat = () => this.clear()
    this.loadChatCards()
  },


  watch: {

    '$route.query.id': {
      immediate: true,
      handler(id) {
        if (id && id !== this.chat.id) {
          this.init(id)
        }
      }
    }
  },


  async activated() {
    const fromPath = this.$route.meta.$from.path
    if (fromPath === '/' || fromPath === '/hotRank') {
      if (this.chat.id && !this.$route.query.id) {
        history.replaceState(null, null, '/ai?id=' + this.chat.id)
      }
    }

    let homeAiChat = null
    while (!homeAiChat) {
      await sleep(10)
      homeAiChat = this.$refs.homeAiChat
      if (homeAiChat) {
        homeAiChat.scrollTo({top: this.$store.state.scrollPosition.ai})
        break
      }
    }
  },


  methods: {

    async loadChatCards(){
      const res = await chatCardV2Api.findAll()
      for(let i = 0;i<res.length;i++){
        const row = res[i]
        this.folders[i].title = row.title || this.folders[i].title
        this.folders[i].resultChatId = row.chatId || this.folders[i].resultChatId
        this.folders[i].subTitle = row.question || this.folders[i].subTitle
      }
    },

    clear() {
      this.chat = {
        id: '',
        question: '',
        status: ''
      }
      this.refPlanPage = {
        records: [],
      }
      this.refQuestions = []
      this.summary = {
        content: '',
        status: '',
        hasLike: false,
        hasDislike: false
      }
      this.refPlanLists = []
    },


    onScroll(e) {
      this.$store.state.scrollPosition.ai = e.target.scrollTop
    },


    /**
     * 停止生成
     */
    async stopGenSummary() {
      if (this.loadings.loadSummary) {
        return
      }
      if (this.summary.status === 'Generating') {
        if (this.sse) {
          this.sse.close()
        }
      }
      await chatApi.resetGenSummaryStatus(this.chat.id)
      this.summary.status = 'Prepared'
      this.chat.status = 'Prepared'
    },

    async resetRefPlans() {
      try {
        this.loadings.loadRefPlans = true
        this.refPlanPage.records = await chatApi.resetRefPlans(this.chat.id)
        this.refPlanPage.total = this.refPlanPage.records.length
        this.refPlanPage.current = 1
      } finally {
        this.loadings.loadRefPlans = false
      }
    },


    share() {
      copyToClipBoard(`灵感严选AI帮我找: ${this.chat.question} ${window.location.href}`)
      this.$toast.success('复制成功~')
    },


    // 向ai提问
    async aiAsk(question) {
      if (!this.hasLogin) {
        return this.$store.commit('SHOW_LOGIN_DIALOG')
      }
      try {
        this.loadings.aiAsk = true
        const res = await chatApi.prepare({question})
        document.querySelector('.home-ai-chat').scrollTo({top: 0, behavior: 'smooth'})
        this.visibles.allRefPlans = false
        this.$router.push(`/ai?id=${res.id}`).then()
        // this.$refs.aiSearchInput.question = ''
      } finally {
        this.loadings.aiAsk = false
      }
      // await this.init(res.id)
    },

    /**
     * 加载聊天记录
     * @param id
     */
    async init(id) {
      if (this.sse) {
        try {
          this.sse.close()
        } catch (e) {
          console.error(e)
        }
      }
      await this.loadChat(id)
      this.loadRefPlans().then(
        () => this.loadRefPlanLists()
      )
      this.loadRefQuestions().then()
      this.loadSummary().then()
    },


    /**
     * 加载chat数据
     */
    async loadChat(id) {
      try {
        this.loadings.loadChat = true
        this.chat = await chatApi.findById(id)
      } finally {
        this.loadings.loadChat = false
      }
    },


    /**
     * 加载相关方案
     */
    async loadRefPlans() {
      try {
        this.loadings.loadRefPlans = true
        this.refPlanPage.records = await chatApi.findRefPlans(this.chat.id)
      } finally {
        this.loadings.loadRefPlans = false
      }
    },


    /**
     * 加载总结
     */
    async loadSummary() {
      try {
        this.loadings.loadSummary = true
        // 生成总结的逻辑
        if (this.chat && this.chat.status === 'Prepared') {
          this.summary.status = 'Generating'
          this.summary.content = ''
          this.summary.hasLike = false
          this.summary.hasDislike = false
          this.summary.id = ''

          const token = this.$store.state.token
          const sse = new EventSource(`/api/web/v2/ai/chat/genSummary?id=${this.chat.id}&Token=${token}`)
          this.sse = sse
          sse.onerror = (e) => {
            console.error(e)
            this.summary.status = 'Abort'
          }

          sse.onmessage = (e) => {
            const res = JSON.parse(e.data)
            this.summary.id = res.id
            if (res.status === 'Complete') {
              sse.close()
              this.summary.status = 'Complete'
            }
            this.summary.content += res.chunk
            this.summary.content = this.summary.content.trimStart()
          }
        }

        // 如果是其他状态，就加载这条生成记录
        else {
          const res = await chatApi.findSummary(this.chat.id)
          if (res) {
            this.summary.status = res.status
            this.summary.content = res.content
            this.summary.hasLike = res.hasLike
            this.summary.hasDislike = res.hasDislike
            this.summary.id = res.id
            this.summary.content = this.summary.content.trimStart()
          }
        }
      } finally {
        this.loadings.loadSummary = false
      }
    },


    async loadRefPlanLists() {
      this.refPlanLists = []
      const planIds = this.refPlanPage.records.map(r => r.id)
      this.refPlanLists = await planListV2Api.findByPlanIds(planIds)
    },


    /**
     * 重新生成总结
     */
    async reGenSummary() {
      if (this.loadings.loadSummary) {
        return
      }
      if (this.summary.status === 'Generating') {
        if (this.sse) {
          this.sse.close()
        }
      }
      await chatApi.resetGenSummaryStatus(this.chat.id)
      this.chat.status = 'Prepared'
      await this.loadSummary()
    },


    /**
     * 加载相关方案
     */
    async loadRefQuestions() {
      try {
        this.loadings.loadRefQuestions = true
        const res = await chatApi.findRefQuestions(this.chat.id)
        this.refQuestions = res.content.split('\n')
      } finally {
        this.loadings.loadRefQuestions = false
      }
    },


    /**
     * 点赞
     */
    async toggleLikeSummary() {
      const res = await chatApi.toggleHasLike(this.summary.id)
      this.summary.hasLike = res.hasLike
      this.summary.hasDislike = res.hasDislike

      if (res.hasLike) {
        this.$toast.success('AI已收到鼓励')
      } else {
        this.$toast.success('已取消鼓励')
      }
    },


    /**
     * 踩
     */
    async toggleDislikeSummary() {
      const res = await chatApi.toggleHasDislike(this.summary.id)
      this.summary.hasLike = res.hasLike
      this.summary.hasDislike = res.hasDislike
      if (res.hasDislike) {
        this.$toast.success('AI会做得更好')
      } else {
        this.$toast.success('已取消差评')
      }
    }


  }
}
</script>

<style lang="less" scoped>
.home-ai-chat {
  background-size: 375px 562px;
  background: url("https://cdn.001ppt.cn/pc2/ai/knowledge/ai-empty-wear-bg.png") no-repeat;
  height: calc(100vh - 100px);
  overflow: auto;
  position: relative;

  &::after {
    content: '';
    width: 100vw;
    height: 30px;
    display: block;
  }
}

.ai-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ai-empty-title {
  width: 233px;
  height: calc(233px * 48 / 392);
  margin: 30px auto 0 auto;
}

.ai-empty-sub-title {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  text-align: center;
  padding-top: 12px;
}

.ai-empty-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 30px;
  margin-top: 10px;
  background-color: rgba(255, 73, 6, 1);
  border-radius: 15px;
  font-size: 14px;
  color: #fff;
}

.ai-empty-folders {
  width: 320px;
  color: rgba(80, 80, 80, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 8px;
  padding-top: 4px;
}

.ai-empty-folder {
  background: url("https://cdn.001ppt.cn/h5/ai/folder.png") no-repeat;
  background-size: 100% 100%;
  width: 299px;
  height: 116px;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 16px;
  box-sizing: border-box;
}

.folder-icon {
  width: 15px;
  height: 16px;
  position: absolute;
  left: 13px;
  top: 5px;
}

.folder-info {
  width: 180px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
}

.folder-title {
  opacity: 0.8;
  color: rgba(255, 73, 6, 1);
  font-size: 14px;
  line-height: 150%;
  font-weight: bold;
}

.folder-sub-title {
  display: flex;
  align-items: center;
  width: 114px;
  height: 20px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 73, 6, 1);
  font-size: 10px;
  border-radius: 16px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 4px 0 8px;
  margin-top: 6px;
}

.folder-sub-title-icon {
  height: 12px;
  width: 12px;
}

.folder-img {
  height: 82px;
  margin-right: 32px;
  object-fit: contain;
}

.right-dialog .right-img {
  width: 320px;
  height: calc(320px * 325 / 375);
  position: fixed;
  z-index: 1001;
  border-radius: 4px;
  top: 170px;
  left: 27px;
  transition: opacity .3s;
}

.right-dialog .right-dialog-shade {
  z-index: 1001;
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eead99;
  opacity: .9;
  transition: opacity .3s;
}

.right-dialog {
  height: auto;
  width: auto;
}

.right-dialog.hidden .right-dialog-shade, .right-dialog.hidden .right-img {
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
}

.ai-chat-header {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px 0 26px;
  font-size: 14px;
  color: #fff;
}

.ai-chat-share-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: #fff;
  color: #fc6f2f;
  border-radius: 100px;
  border: 1px solid #fc6f2f;
  box-sizing: border-box;
  padding: 4px 8px;
  flex-shrink: 0;

  img {
    height: 12px;
    width: 12px;
    margin-left: 4px;
  }
}

.ai-chat-card {
  background: #fff;
  border-radius: 20px;
  margin: 20px 27px;
  min-height: 200px;
  position: relative;
}

.ai-chat-card .loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #f15448;
  padding-left: 8px;
}

.ai-chat-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0 0 10px;
}

.ai-chat-card-title {
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: 14px;

  img {
    height: 20px;
    width: 21px;
    margin-right: 4px;
  }
}

.ai-chat-card-btn {
  width: 75px;
  height: 26px;
  font-size: 12px;
  font-weight: 500;
  background-color: rgba(247, 148, 132, .3);
  border: 1px solid #f15448;
  border-radius: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(.6) translateX(7px);

  img {
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }
}


.ai-chat-ref-plans {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 8px;
  max-height: 280px;
  overflow: hidden;
}

.ai-chat-ref-plans.show-all {
  max-height: 99999999px;
}

.toggle-all-ref-plans {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}

.toggle-all-ref-plans-btn {
  color: #fff;
  background: #ff4906;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 23px;
  font-size: 11px;
  border-radius: 100px;
  padding: 0 12px;
  box-sizing: border-box;
}

.ref-plan-item {
  height: 73px;
  width: 301px;
  margin-top: 7px;
  background: #fff6ec;
  border-radius: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;

  .index {
    height: 20px;
    width: 20px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 100px;
    border: 1px solid #000;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    &.index-1 {
      border-color: #d43030;
    }

    &.index-2 {
      border-color: #ff5733;
    }

    &.index-3 {
      border-color: #ff8d1a;
    }
  }

  .cover {
    width: 100px;
    height: 56px;
    border-radius: 3px;
    margin-left: 8px;
  }

  .info {
    padding-left: 9px;
    font-size: 12px;

    .title {
      color: #d43030;
      max-width: 150px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      padding-bottom: 8px;
    }

    .tags {
      color: #000;
      opacity: .5;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
    }
  }
}


.summary {
  background: #fff;
  padding: 17px 22px 15px 29px;
  border-radius: 20px;
  font-size: 10px;
  min-height: 200px;
  line-height: 1.5;
  color: rgba(112, 112, 112, 1);
  margin: 16px 9px;

  &.generating::after {
    content: '';
    display: inline-block;
    animation: blink .3s linear 0s infinite;
    width: 14px;
    height: 4px;
    background-color: #333;
  }
}


.summary-tip {
  color: #888;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 9px 15px 12px;
}

.summary-handle-icon {
  width: 14px;
  height: 14px;
  opacity: .5;
  margin-left: 8px;
  cursor: pointer;

  &.reverse {
    transform: rotate(180deg);
  }

  &.active {
    opacity: 1;
  }
}


.question-item {
  font-size: 12px;
  color: #707070;
  padding: 8px 9px 16px 9px;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    transform: scaleY(0.5);
    background: #e9e9e9;
  }

  &.last::after {
    display: none;
  }
}


.ref-plan-lists {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
}

.plan-list {
  padding: 4px 8px;
  background: #f3f3f5;
  font-size: 10px;
  border-radius: 100px;
  margin-right: 8px;
  cursor: pointer;
  color: #444;
  margin-bottom: 12px;
  box-sizing: border-box;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  flex-wrap: nowrap;
  word-break: break-all;
}
</style>
