<template>
  <div class="chat-renderer" ref="chatRender">
<!--            <pre>-->
<!--              {{ content }}-->
<!--            </pre>-->
    <div class="tip" v-if="status!== 'Generating' && (!renderText || !renderText.length)">
      <div>灵感AI还在实习期</div>
      <div>如果暂未生成</div>
      <div>点击右上角「换个总结」即可刷新</div>
    </div>
    <template v-if="struct">
      <template v-for="(item, index) in struct" v-if="item">
        <p :class="{generating: status==='Generating' && index===struct.length-1}" v-if="item.type === 'line'">
          {{ item.content }}</p>
        <div :class="{generating: status==='Generating' && index===struct.length-1}" class="change-line"
             v-if="item.type==='changeLine'"></div>
        <div :class="{generating: status==='Generating' && index===struct.length-1}" class="plan"
             v-if="item.type==='plan' && item.planTitle">
          <a onclick="return false" @click="$router.push(`/caseDetail/${item.planId}`)" :href="`/caseDetail/${item.planId}`"
             class="plan-title">《{{ item.planTitle }}》</a>
          {{ item.emoji }}{{ item.content }}
        </div>
      </template>
    </template>
  </div>
</template>

<script>

export default {
  props: ['content', 'status'],
  watch: {
    content: {
      immediate: true,
      handler() {
        const lines = this.content.split('\n')
        const struct = []
        for (const line of lines) {
          const values = line.split('<tab')
          for(let i = 0;i<values.length;i++){
            let val = values[i]
            if(!val){
              continue
            }
            if(val.startsWith && val.startsWith('>')){
              val = val.slice(1)
              values[i] = val
            }
          }

          const type = values[0] || 'line'
          const emoji = values[1] || ''
          const planId = values[2] || ''
          const planTitle = values[3] || ''
          const content = values[4] || ''
          const item = {
            type,
            emoji,
            planId,
            planTitle,
            content
          }
          struct.push(item)
        }

        this.struct = struct

        if (this.$refs.chatRender) {
          this.renderText = this.$refs.chatRender.innerText.trim()
        }
      }
    }
  },


  data() {
    return {
      struct: [],
      renderText: ''
    }
  }

}
</script>

<style lang="less">
.chat-renderer * {
  font-size: 10px;
}
</style>
<style lang="less" scoped>
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}


.generating::after {
  content: '';
  display: inline-block;
  animation: blink .3s linear 0s infinite;
  width: 14px;
  height: 4px;
  background-color: #333;
}


.chat-renderer a {
  color: #02A9F1;
}


.tip {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1.5;
  padding-top: 50px;
  opacity: .4;
  height: 100px;
}

.change-line {
  width: 100%;
  height: 20px;
}
</style>
